<template>
  <section class="finally">
    <header class="finally__header">
      <h2 class="finally__header-lead">
        <AltSvg :src="TitleLead" alt="Special Movie"></AltSvg>
      </h2>
      <h1 class="finally__header-title">
        <AltSvg :src="TitleText" alt="BTS MUSIC JOURNEY"></AltSvg>
      </h1>
    </header>

    <div class="finally__content">
      <div class="finally__content-box finally__content-box--lang_ja">
        <p>
          BTS が、 ARMY に語るニューアルバムの魅力の全て。
        </p>
        <p>
          前編・後編の 2 部構成で配信したスペシャルムービー『 BTS MUSIC JOURNEY 』と、<br class="hidden--on_sp">
          テレビ東京 6 局ネットで放送された特別番組『 BTS JOURNEY 〜7人の旅〜』は、<br class="hidden--on_sp">
          2020 年 9 月 30 日を以て公開を終了しました。
        </p>
        <p>
          ご視聴ありがとうございました！
        </p>
      </div>
      <div class="finally__content-box finally__content-box--lang_en">
        <p>
          BTS tells ARMY all about the new album.
        </p>
        <p>
          The special movie "BTS MUSIC JOURNEY",<br class="hidden--on_sp">
          which was distributed in two parts (Part 1 and Part 2),<br class="hidden--on_sp">
          and the special program "BTS JOURNEY ~ Shichinin no Tabi ~",<br class="hidden--on_sp">
          which was aired on the network of six TV stations in Tokyo,<br class="hidden--on_sp">
          have ended their release on September 30, 2020.
        </p>
        <p>
          Thank you for watching!
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import AltSvg from '~/_/components/atoms/AltSvg/AltSvg'

import TitleLead from './specialmovie.svg'
import TitleTextLarge from './btsmusicjourney.large.svg'
import TitleTextSmall from './btsmusicjourney.small.svg'

export default {
  components: {
    AltSvg,
  },
  data: vm => ({
    TitleLead,
  }),
  computed: {
    TitleText: vm => vm.$root.isLayout('pc') ? TitleTextLarge : TitleTextSmall,
  },
}
</script>

<style lang="scss" scoped>
.finally {
  &__header {
    &-lead {
      height: 19cp;
      @include asPC {
        height: 26cp;
      }
    }

    &-title {
      height: 74cp;
      margin-top: 32cp;
      @include asPC {
        height: 36cp;
      }
    }
  }

  &__content {
    margin-top: 64cp;

    &-box {
      @include asPC {
        text-align: center;
      }
      &:not(:first-child) {
        padding-top: 32cp;
      }
      &:not(:last-child) {
        padding-bottom: 32cp;
        border-bottom: 1px solid rgba(#fff, .38);
      }
    }
  }
}
</style>
