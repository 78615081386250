<template>
  <div class="container">
    <main class="main" role="main">
      <slot name="content"></slot>
    </main>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.container {
  background-image: url(./back_loop.png);
  background-position: top center;
  background-repeat: repeat-y;
  background-size: 100%;
  @include asPC {
    background-size: 1440cp;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 64cp 16cp;
  @include asPC {
    padding: 64cp 0;
  }
}
</style>
