<template>
  <span class="alt-svg">
    <i class="alt-svg__icon"
      v-html="src">
    </i>
    <span v-if="alt"
      class="alt-svg__text">
      {{ alt }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: String,
  }
}
</script>

<style lang="scss" scoped>
.alt-svg {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__text {
    position: absolute;
    display: block;
    overflow: hidden;
    font-size: 0;
    appearance: none;
  }

}
</style>
