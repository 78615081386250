import {
  ENV,
  LANG,
} from '~/../.apprc'

const state = {
  lang: LANG,
  screen: 'small',
  layout: 'sp',
  isBrave: false,
  playing: undefined,
}

export default {
  debug: (ENV !== 'production'),
  _state: Object.freeze(Object.assign({}, state)),
  state,
  update (key, val) {
    const _val = this.state[key]
    if (val === _val) return
    if (this.debug) console.log(`[UPDATE] $root.state.${key}: ${_val} -> ${val}`)
    return this.state[key] = val
  },
  init (key) {
    if (!this._state.hasOwnProperty(key)) return console.error(`[INIT] $root.state.${key} was not found.`)

    const val = this._state[key]
    if (this.debug) console.log(`[INIT] ${key}: ${val}`)
    return this.state[key] = val
  },
}
