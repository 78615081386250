import '@babel/polyfill'

import Vue from 'vue'

import App from '~/app.vue'

import store from '~/_/store'
import Browser from '~/_/Browser'

const browser = new Browser()

new Vue({
  el: '#app',
  render: h => h(App),
  data: {
    store,
  },
  computed: {
    state: vm => vm.store.state,
  },
  methods: {
    isLang (key) {
      if (typeof key !== 'string') return
      return (this.state.lang === key)
    },
    isScreen (key) {
      if (typeof key !== 'string') return
      return (this.state.screen === key)
    },
    isLayout (key) {
      if (typeof key !== 'string') return
      return (this.state.layout === key)
    },
  },
  created: async function () {
    // Update: this.store.state.lang
    this.store.update('lang', browser.language)

    // Update: this.store.state.screen
    this.store.update('screen', browser.screen)

    // Update: this.store.state.layout
    this.store.update('layout', browser.layout)

    // Update: this.store.state.isBrave
    const isBrave = await browser.isBrave()
    this.store.update('isBrave', isBrave)

    /**
     * Additional processes in front-end
     * ---------------------------------------------------------------------- */

    if (!window) return

    // Update stores when the screen is resized
    window.addEventListener('resize', e => {
      this.store.update('screen', browser.screen)
      this.store.update('layout', browser.layout)
    }, false)

    // Register Vue instance as the global variable in development
    if (!this.store.debug) return
    window.$app = this
  },
})
