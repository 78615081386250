<template>
  <TemplateHome>
    <template #content>
      <Finally></Finally>
    </template>
  </TemplateHome>
</template>

<script>
import TemplateHome from '~/_/templates/Home'
import Finally from '~/_/components/organisms/Finally/Finally'

export default {
  components: {
    TemplateHome,
    Finally,
  },
}
</script>

<style lang="scss">
@import '~/_/base.scss';

.finally {
  @include asPC {
    width: 600cp;
    margin: auto;
  }
}
</style>
