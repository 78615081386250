import {
  SCREEN,
} from '~/../.apprc'

export default class {
  constructor () {
    this._language = 'en'
    this._screen = 'small'
    this._layout = 'sp'

    this.navigator = window.navigator
  }

  get language () {
    if (!this.navigator) return

    const languages = [].concat(
      this.navigator.languages
      ||
      this.navigator.language
    )

    return languages.reduce((result, key) => {
      if (/^ja/.test(key)) result = key
      // if (/^ko/.test(key)) result = key
      return result
    }, this._language)
  }

  get width () {
    return window.innerWidth
  }

  get screen () {
    if (!this.width) return this._screen
    return Object.keys(SCREEN).reduce((result, key) => {
      const val = SCREEN[key]
      if (this.width >= val) result = key
      return result
    }, this._screen)
  }

  get layout () {
    switch (this.screen) {
      case 'large':
      case 'medium':
        return 'pc'
      case 'small':
      default:
        return this._layout
    }
  }

  async isBrave () {
    if (!this.navigator.brave) return false
    if (!this.navigator.brave.isBrave) return false
    return await this.navigator.brave.isBrave()
  }
}
