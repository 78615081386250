module.exports = {
  ENV: process.env.NODE_ENV || 'development',
  DIST: process.env.NODE_DIST || 'jp',
  get LANG () {
    switch (this.DIST) {
      case 'com':
        return 'en'
      default:
        return 'ja'
    }
  },
  CANVAS: {
    sp: 320,
    pc: 1440,
  },
  SCREEN: {
    small: 320,
    medium: 600,
    large: 1272,
  },
}
